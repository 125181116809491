<template>
  <main 
    :class="classes">  
    <!-- Loading -->
    <div 
      class="layout-feedback__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div class="view-feedback__header" v-if="!loading">
      <div class="view-feedback__texts">
        <h2 class="feedback__subtitle">{{ title }}</h2>
        <div class="feedback__subdescription">{{ description }}</div>
      </div>
    </div>

    <form
      class="view-feedback__body"
      v-if="!loading && !output"
      @submit="onSubmit">
      <div
        class="view-feedback__question"
        :key="code"
        v-for="code in codes">
        <component
          :errors="getErrors(`answers.${code}`)"
          :data="getData(code)"
          :is="getComponent(code)"
          v-model="values[code]"
        />
      </div>

      <div class="view-feedback__submit">
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          @click="submit"
        >{{ $t('user-portal.feedback_submit') }}</actions-button>
      </div>
    </form>

    <div
      class="view-feedback__body"
      v-if="!loading && output !== null">
      <div class="ql-editor view-feedback__output" v-html="output"></div>
    </div>
  </main>
</template>

<script>
import MixinErrors from '@/helpers/errors'
import CommentInput from '../../components/comment'
import StarsInput from '../../components/stars'

export default {
  name: 'Feedback',

  inject: [
    '$feedback',
  ],
  
  mixins: [ 
    MixinErrors,
  ],

  data() {
    return {
      loading: false,
      values: null,
      output: null
    }
  },

  computed: {
    classes() {
      return {
        'container': true,
        'layout-feedback__body': true,
        '-is-loading': this.loading
      }
    },

    codes() {
      return Object.keys(this.values ?? {})
    },

    description() {
      return this.$feedback.feedback?.description ?? ''
    },

    feedback() {
      return this.$feedback.feedback
    },

    title() {
      return this.$feedback.feedback?.title ?? ''
    }
  },

  methods: {
    getComponent(code) {
      let question = this.$basil.get(this.feedback, 'questions', []).find(question => question.code === code)

      if (this.$basil.isNil(question)) {
        return
      }

      if (question.type === 'stars') {
        return StarsInput;
      }

      if (question.type === 'user_input' && question.input === 'textarea') {
        return CommentInput;
      }
    },

    getData(code) {
      let question = this.$basil.get(this.feedback, 'questions', []).find(question => question.code === code)

      if (this.$basil.isNil(question)) {
        return
      }

      return question
    },

    onSubmit(e) {
      e.preventDefault()
    },

    async reset() {
      this.loading = true
      try {
        await this.$feedback.view({ token: this.$route.params.token })
        this.values = this.feedback.questions.reduce((carry, question) => {
          carry[question.code] = this.$basil.get(question, 'default', '')

          return carry
        }, {})
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async submit() {
      this.loading = true
      try {
        const data = await this.$feedback.send({ token: this.$route.params.token, answers: this.values })
        this.output = data.output
      } catch (e) {
        if (e.status === 423) {
          this.output = this.$t('user-portal.feedback_already_submitted')
        } else {
          this.handleErrors(e)
        }
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.reset()
  }
}
</script>